import React, { useCallback, useEffect, useState } from 'react'
import { HiOutlineSearch } from 'react-icons/hi';
import { Buttons } from '../../CustomUIElements/Buttons/Buttons';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addStudentToGroup, fecthStudentsInGroup, fetchStudents, getSingleGroup, removeFromGroup } from '../../utils/students';
import useAuthStore from '../../store/authStore';
import ModalContainer from '../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../CustomUIElements/Inputs/Inputs';
import ConfirmationModal from '../../CustomUIElements/Modal/ConfirmationModal';
import { toast } from 'react-toastify';
import Table from '../../CustomUIElements/Table/Table';
import { addUserToGroupChat, removeUserFromGroup } from '../../utils/chatFunctions';
import SelectUI from '../../CustomUIElements/Inputs/Select';

function GroupStudents() {
    const { id, courseId } = useParams();
    const [students, setStudents] = useState([])
    const [searchQuery, setSearchQuery] = useState("");
    const [groupStudents, setGroupStudents] = useState([])
    const [groupInfo, setGroupInfo] = useState([])
    const [openAddModal, setOpenAddModal] = useState(false)
    // const [modalIsOpen, setModalIsOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [formValues, setFormValues] = useState({
        name: "",
        desc: ""
    })

    const navigate = useNavigate()

    // const [openModal, setOpenModal] = useState({
    //     modalName: "",
    //     showModal: false,
    //   });

    const { userProfile, removeUser } = useAuthStore()
    const [selected, setSelected] = useState(null)
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [showStudent, setShowStudent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [rowData, setRowData] = useState(null)
    const { isLoading: fetchingGroupInfo } = useQuery(
        "fetch group Info",
        () => getSingleGroup({ id: parseInt(id) }),
        {
            onSuccess: ({ data }) => {
                setGroupInfo(data.data);
            },
            onError: ({ response }) => {
                if (response.data.status === 401) {
                    removeUser();
                }
            },
            enabled: true,
            retry: false
        }
    );
    const { isError, isLoading, isFetching, refetch } = useQuery(
        "fecthStudentsInGroup",
        () => fecthStudentsInGroup({ groupId: parseInt(id), learningInstitutionID: parseInt(userProfile?.user?.learningInstitutionId), courseId: parseInt(courseId) }),
        {
            onSuccess: ({ data }) => {
                setGroupStudents(data.data);
            },
            onError: ({ response }) => {
                if (response.data.status === 401) {
                    removeUser();
                }
            },
            enabled: true,
            retry: false
        }
    );

    const { isLoading: loadingStudents, isRefetching } = useQuery("fetchStudents", fetchStudents, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            // console.log(data.data)
            let d = []
            const res = data?.data
            res?.forEach((dd, i) => {
                let obj = { value: dd?.id, label: `${dd?.firstName} ${dd?.lastName}` }
                d.push(obj)
            })
            console.log("🚀 ~ GroupStudents ~ d:", d)
            setStudents(d)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })



    const addToGroupMutation = useMutation({
        mutationFn: ({ groupID, userID, courseId }) => addStudentToGroup({ groupID, userID, courseId })
    })

    const handleBulkAdd = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            await Promise.all([selected]?.map(async (row) => {
                // Call the mutation function with the ID
                const s = row.label.split(" ")
                const userInfo = {
                    id: row?.value,
                    firstName: s[0]?.trim(),
                    lastName: s[1]?.trim()
                }
                // console.log(userInfo)
                const response = await addUserToGroupChat(groupInfo?.reference, userInfo).then(async (res) => {
                    console.log(res)
                    if (!res) {
                        setLoading(false)
                    } else {
                        await addToGroupMutation.mutateAsync({ groupID: parseInt(id), userID: row?.value, courseId: parseInt(courseId) });
                    }
                })
                console.log(response)
                toast.success("Operation completed successfully");
                refetch();
                setOpenAddModal(false);
                setLoading(false)
                setSelected([])
            }));
        } catch (error) {
            console.error("Bulk delete error:", error);
            if (error?.response?.status === 500) {
                toast.error(`${error?.response?.data}`)
            } else {
                toast.error("An error occurred during bulk activate operation");
            }
            setLoading(false)
        }
    };


    const removeUserById = (id) => {
        setSelected(prevSelected => prevSelected.filter(user => user.id !== id));
    };

    const handleDelete = async () => {
        setLoading(true)
        const res = await removeUserFromGroup(groupInfo?.reference, rowData?.id)
        console.log(res)
        if (res) {
            await removeFromGroup({ groupId: id, userId: rowData?.id, courseId: courseId }).then(() => {
                refetch()
                setOpenModal({})
                setLoading(false)
            }).catch(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }

    // console.log("selected", selected)
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <h1 className="text-[20px] font-semibold mt-[3rem] mb-[.5rem]">{groupInfo?.name}</h1>
            <p className='mb-[1rem]'>{groupInfo?.description}</p>
            <Link to={"/lecturer/dashboard/groups"} className='mb-[3rem] block text-[12px] underline w-fit text-[#008FD2]'>View All Groups</Link>
            <div className='flex justify-between items-center mb-8'>
                <div className="relative">
                    <input
                        className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="absolute top-[14px] left-[15px] text-[20px]">
                        <HiOutlineSearch />
                    </span>
                </div>
                <div className='flex gap-[10px]'>
                    <div className='w-[200px]'>
                        <Buttons text="View Group Idea(s)" variant="primary" handleClick={() => navigate(`/lecturer/dashboard/group-ideas/course/${courseId}/group/${id}`)} />
                    </div>
                    <div className='w-[150px]'>
                        <Buttons text="Add Student" variant="primary" handleClick={() => setOpenAddModal(true)} />
                    </div>
                </div>
            </div>
            <Table
                header={
                    [
                        { name: "First Name", identifier: "firstName" },
                        { name: "Last Name", identifier: "lastName" },
                        { name: "Email", identifier: "email" },
                    ]}
                options={{
                    variant: "primary",
                    tableStyle: "striped-even",
                    allowActions: true,
                    allowSorting: true,
                    showPagination: true,
                    actions: {
                        delete: true,
                        hideActivate: true,
                    }
                }}
                data={[...groupStudents]}
                loading={isLoading}
                // path={`/lecturer/dashboard/groups`}

                searchQuery={searchQuery}
                setOpenModal={setOpenModal}
                rowData={rowData}
                setRowData={setRowData}
            />
            {openAddModal && (
                <ModalContainer height="400px" width="500px">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem] mb-4">
                            <h3 className="">Add Student</h3>
                            <LiaTimesSolid
                                onClick={() =>
                                    setOpenAddModal(false)
                                }
                                className="cursor-pointer"
                            />
                        </div>
                        <form action="" className='' >
                            {/* {selected?.length > 0 && <div className='flex gap-4 flex-wrap mb-2'>
                                {selected?.map((stu, i) => (
                                    <div key={i} className='flex justify-between gap-[10px] w-fit px-[12px] py-[5px] rounded-[50px] border text-[12px]'>
                                        <p className=' ' >{stu?.firstName} {" "} {stu?.lastName}</p>
                                        <p className='text-red-500 font-semibold cursor-pointer' onClick={() => removeUserById(stu?.id)}>x</p>
                                    </div>
                                ))}
                            </div>} */}
                            {/* <div className='w-full relative mb-4' onClick={() => setShowStudent(true)}>
                                <Inputs type="text" label="Search by student name" value={formValues.name} handleChange={(e) => setFormValues((prev) => ({ ...prev, name: e.target.value }))} />
                            </div> */}
                            <SelectUI customStyle={`border text-[15px] mb-4`} placeholder={`Search by student name`} isMulti={false} options={students} handleChange={setSelected} />
                            {/* {selected && (
                                <div className='bg-white rounded-md  shadow-lg mb-4 border '>
                                    <p
                                        onClick={() => setShowStudent(false)}
                                        className="cursor-pointer w-fit ml-auto text-[#008FD2] text-[12px] p-2"
                                    >Hide</p>
                                    <div className='max-h-[400px] overflow-y-auto text-[15px] space-y-2 '>
                                        {filteredStudents?.map((stu, i) => (
                                            <p key={i} className='hover:bg-slate-200 p-4 px-8 cursor-pointer' onClick={() => setSelected([...selected, stu])}>{stu?.firstName} {" "} {stu?.lastName}</p>
                                        ))}
                                    </div>
                                </div>
                            )} */}
                            {selected &&
                                <Buttons text={loading ? "loading..." : `Add ${selected?.length > 0 ? selected?.length : ""} Student`} variant={loading ? "disabled" : "primary"} handleClick={handleBulkAdd} />
                            }
                        </form>
                    </div>
                </ModalContainer>
            )}

            {/*
            <ConfirmationModal
                  showModal={openModal?.modalName === "enable" && openModal?.showModal}
                  onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                  message="Are you sure you want to activate this Student?"
                  //    onConfirm={() => mutate()}
              />
              <ConfirmationModal
                  showModal={openModal?.modalName === "disable" && openModal?.showModal}
                  onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                  message="Are you sure you want to de-activate this Student?"
                 
              />
    
          {openModal.showModal && openModal.modalName === "delete" && (
              <ModalContainer height="auto">
              <ConfirmationModal
                 showModal={openModal.modalName === "delete" && openModal.showModal}
                 onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                 message="Are you sure you want to delete this Student?"
              //    onConfirm={() => mutate()}
                 
              />
              </ModalContainer>
            )}
        */}


            <ConfirmationModal
                message="Are you sure you want to delete this group?"
                showModal={openModal?.showModal && openModal.modalName === "delete"}
                onConfirm={handleDelete}
                onCancel={() => setOpenModal({})}
                loading={loading}
            />
        </div>
    )
}

export default GroupStudents