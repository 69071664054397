import React, { useState, useEffect } from 'react'
import { ResetPassword, Section1, Section2 } from './sections'
import useAuthStore from '../../../store/authStore'

const Profile = () => {
  const {userProfile, saveuserProfile} = useAuthStore();
  const [user, setuser] = useState([])

  useEffect(() => {
    setuser(userProfile?.user);
  }, []);
  



  return (
    <div className="relative top-[8%] md:text-[15px] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            <Section2 user={user}/>
            <ResetPassword/>
        </div>
  )
}

export default Profile