import React, { useEffect, useState } from 'react'
import { Buttons } from '../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../store/authStore'
import Inputs from '../../CustomUIElements/Inputs/Inputs'
import Scale from '../../CustomUIElements/Loaders/ScaleLoader'
import axios from 'axios'
import { addGrade, deleteGrade, getLIGrades, updateGrade } from '../../utils/addSetting'
import { toast } from 'react-toastify'
import { useTheme } from '../../context/ThemeContext'
import apiClient from "../../libs/http-common";
import { uploadFile } from '../../utils/uploadPicture'
import { useQuery } from 'react-query'
import { fetchSubscriptions } from '../../utils/fetchSubscriptions'
import { fetchUserProfile } from '../../utils/fetchUserProfile'
import { useNavigate } from 'react-router-dom'
import { ChromePicker } from 'react-color';

const initialGradeState = [
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
    {
        minScore: "",
        maxScore: "",
        value: ""
    },
]

function Settings() {
    const [tab, setTab] = useState("profile")
    const { userProfile, removeUser } = useAuthStore()
    const [loading, setLoading] = useState(false)
    const [grading, setGrading] = useState(initialGradeState)
    const { theme, themeStyles } = useTheme()
    const [isSelected, setIsSelected] = useState("")
    const [reload, setReload] = useState(false)

    const fetchGrades = async () => {
        setLoading(true)
        await getLIGrades({ id: userProfile?.user?.learningInstitutionId }).then((res) => {
            console.log(res?.data?.data)
            if (res?.data?.data?.length < 1) {
                setGrading(initialGradeState)
            } else {
                const gradeData = res?.data?.data
                let gradingCopy = [...grading]
                gradeData?.forEach((ele, i) => {
                    gradingCopy[i].id = ele.id
                    gradingCopy[i].maxScore = ele.maxScore;
                    gradingCopy[i].minScore = ele.minScore;
                    gradingCopy[i].value = ele.value;
                })
                setGrading(gradingCopy)
            }
            setLoading(false)
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            console.log(err)
            setLoading(false)
        })
    }
    useEffect(() => {
        fetchGrades()
    }, [reload])
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-screen">
            <h1 className="text-[20px] font-semibold mb-[3rem]">Settings</h1>
            <div className='my-[24px] grid grid-cols-12 h-[70vh]'>
                <div className='col-span-3 border border-gray-400 border-r-0 py-[24px]'>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:text-white px-[24px] ${tab === "profile" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("profile")} style={{ backgroundColor: tab === "profile" && theme.backgroundColor, color: tab === "profile" ? theme.color : theme.backgroundColor }}>Profile</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer  hover:text-white px-[24px] ${tab === "customization" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("customization")} style={{ backgroundColor: tab === "customization" && theme.backgroundColor, color: tab === "customization" ? theme.color : theme.backgroundColor }}>Customization</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer  hover:text-white px-[24px] ${tab === "subscription" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("subscription")} style={{ backgroundColor: tab === "subscription" && theme.backgroundColor, color: tab === "subscription" ? theme.color : theme.backgroundColor }}>Subscription</p>
                    <p className={`text-[15px] p-4 mb-4 cursor-pointer hover:text-white px-[24px] ${tab === "grading" && "bg-[#008FD2] text-white"}`} onClick={() => setTab("grading")} style={{ backgroundColor: tab === "grading" && theme.backgroundColor, color: tab === "grading" ? theme.color : theme.backgroundColor }}>Grading System</p>
                </div>
                <div className='col-span-9  border-gray-400 bg-[#008FD2] p-[24px]' style={{ backgroundColor: theme.backgroundColor }}>
                    {tab === "profile" && <Profile userProfile={userProfile} />}
                    {tab === "customization" && <Customization />}
                    {tab === "subscription" && <Subscription />}
                    {tab === "grading" && <Grading grading={grading} setGrading={setGrading} isSelected={isSelected} setIsSelected={setIsSelected} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} loading={loading} setReload={setReload} reload={reload}/>}
                </div>
            </div>
        </div>
    )
}

export default Settings

const Profile = () => {
    const { userProfile, saveUserProfile, removeUser } = useAuthStore()
    const [user, setUser] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [sub, setSub] = useState([])
    const navigate = useNavigate()
    const handleChange = (e) => {
        const name = e.target.name
        const value = e.target.value
        setUser((prev) => ({ ...prev, [name]: value }))
    }
    const { refetch } = useQuery(
        " fetchUserProfile",
        () =>
            fetchUserProfile(userProfile?.user?.id),
        {
            enabled: false,
            refetchOnMount: true,
            refetchOnWindowFocus: true,
            retry: false,
            retryOnMount: true,
            onSuccess: (data) => {
                const token = userProfile?.token
                const u = data?.data?.data
                const dataToSave = {
                    token,
                    user: u
                }
                saveUserProfile(dataToSave)
                toast.success("Profile updated successfully")
            },
            onError: (error) => {
                const { response } = error;
                if (response?.status === 401) {
                    removeUser();
                    navigate("/login")
                } else {
                    console.error("An error occurred:", error);
                }
            },
        }
    );
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(user)
        setIsLoading(true)
        await apiClient.put("/Accounts/updateUser", user
        ).then((res) => {
            setIsLoading(false)
            refetch()
        }).catch((err) => {
            console.log(err)
            toast.error("Error updating profile")
            setIsLoading(false)
        })
    }

    const { } = useQuery("fetchSubscriptions", fetchSubscriptions, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setSub(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    const findSubID = () => {
        if (sub) {
            const id = sub?.find((s) => s?.topic === userProfile?.subscription)?.id
            return id
        }
    }

    useEffect(() => {
        if (sub && userProfile) {
            setUser({
                firstName: userProfile?.user?.firstName,
                lastName: userProfile?.user?.lastName,
                emailAddress: userProfile?.user?.email,
                mobileNumber: userProfile?.user?.phoneNumber,
                userId: userProfile?.user?.id,
                subscriptionId: findSubID() || 0,
                organisationName: userProfile?.user?.organisationName
            })
        }
    }, [userProfile, sub])

    return (
        <form className='text-white'>
            <div className='flex items-center justify-between mb-[16px]'>
                <p className='text-[19px] font-semibold'>Profile Information</p>
                <div className='w-fit'>
                    <Buttons text={isLoading ? "Saving..." : "Save Changes"} variant={isLoading ? "disabled" : "cancel"} handleClick={handleSubmit} type="submit" />
                </div>
            </div>
            <div className='space-y-8'>
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='First Name' value={user?.firstName} name='firstName' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Last Name' value={user?.lastName} name='lastName' onChange={handleChange} />
                <input type="email" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Email Address' value={user?.emailAddress} name='emailAddress' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Phone Number' value={user?.mobileNumber} name='mobileNumber' onChange={handleChange} />
                <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Institution Name' value={user?.organisationName} name='organisationName' onChange={handleChange} />
                {userProfile?.user?.userRole === "Lecturer" &&
                    <input type="text" className='w-full p-3 border bg-transparent border-white text-white placeholder:text-white rounded-md text-[15px] outline-none' placeholder='Course Title' value={user?.courseName} name='courseName' onChange={handleChange} />
                }
            </div>
        </form>
    )
}
const Customization = () => {
    const [selectedFile, setSelectedFile] = useState(null)
    const [logoUrl, setLogoUrl] = useState(null)
    const { setLogo, color, setColor, setReload, logo, theme } = useTheme()
    const [loading, setLoading] = useState(false)
    const handleSave = async () => {
        const token = window.localStorage.getItem("token");
        let l;
        setLoading(true)
        if (selectedFile) {
            await uploadFile(selectedFile, 2, ["jpg", "jpeg", "png"]).then((res) => {
                l = res?.data?.data?.path
                setLogo(res?.data?.data?.path)
                setSelectedFile(null)
                // toast.success("Logo uploaded successfully")
            }).catch((err) => {
                console.log(err)
                setLoading(false)
                toast.error(`${err?.response?.data?.status}`)
            })
        }
        await apiClient.post("/Settings/add", {
            name: "customization",
            logoUrl: l ? l : logo,
            primaryColour: color?.backgroundColor,
            secondaryColour: "string",
            description: "string",
            value: 0,
            jsonValue: "string",
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((res) => {
            toast.success("Customization added successfully")
            setReload(true)
        }).catch((err) => { 
            if (err.status === 500) {
                toast.error(`${err?.data}`);
              } 
        }).finally(() => {
            setLoading(false)
           
        })
    }
    const handleColorChnage = (color) => {
        setColor({ backgroundColor: color.hex, color: "#FFFFFF" })
    }
    const handleFileChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        setSelectedFile(file)
        const fUrl = URL.createObjectURL(file)
        setLogoUrl(fUrl)
    }
    return (
        <div className='text-white'>
            <div className='flex items-center justify-between mb-[16px]'>
                <p className='text-[19px] font-semibold'>Customization</p>
                <div className='w-fit'>
                    <Buttons text={loading ? "Saving..." : "Save Changes"} variant={loading ? "disabled" : "cancel"} handleClick={handleSave} />
                </div>
            </div>
            <div className='space-y-8'>
                <div>
                <p className='text-[16px] mb-2'>For better experience, we encourage you use an image with this dimension width - 158px, height -32px</p>
                    <div className='flex items-center gap-[20px]'>
                        <label htmlFor="file" className='w-[200px] rounded-md flex justify-center items-center p-4 border border-white text-white placeholder:text-white'>
                            <input type="file" name="" id="file" className='hidden' onChange={(e) => handleFileChange(e)} />
                            <p className='text-center text-[15px]'>{selectedFile ? "Logo selected" : "Upload Institution Logo"}</p>
                        </label>
                        {selectedFile && <p className='cursor-pointer underline text-[14px]' onClick={() => {
                            setSelectedFile(null);
                            setLogoUrl(null)
                        }}>Remove selected Logo</p>}
                    </div>
                    {logoUrl && <div className='mt-4 text-[16px]'> <p>This is a preview of how your your logo will appear, if it looks okay you can save these changes.</p><img src={logoUrl} alt='Preview' width={158} height={60} className='w-[158px] h-[60px] mt-4 object-cover' /></div>}
                </div>
                <p className='text-[16px]'>Use the color tool below to select your Instituition's Primary Color</p>
                <ChromePicker color={theme.backgroundColor} onChange={(color) => handleColorChnage(color)} disableAlpha={true} />
            </div>
        </div>
    )
}

const Subscription = () => {
    return (
        <div className='text-white text-[16px]'>
            <p>You're currently subscribed to Hyderate&apos;s <span className='font-semibold underline'>Education</span> plan.</p>
        </div>
    )
}

const Grading = ({ grading, setGrading, isSelected, setIsSelected, fetchGrades, removeUser, userProfile, loading, setReload, reload }) => {
    return (
        <div>
            <p className='text-[16px] text-white mb-8'>Use the interface below to define your Learning Institution&apos;s grading system</p>
            <div className='space-y-4'>
                {loading ? <center><Scale color="white" /></center> :
                    <>
                        {grading?.map((item, index) => (
                            <Grade key={index} item={item} grading={grading} setGrading={setGrading} isSelected={isSelected} onClick={() => setIsSelected(index)} index={index} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} setReload={setReload} reload={reload}/>
                        ))}
                        {/* {grading && [...Array(Math.max(0, 7 - parseInt(grading?.length || 0)))]?.map((item, index) => (
                            <Grade key={index} item={item} grading={grading} setGrading={setGrading} isSelected={isSelected} onClick={() => setIsSelected(index + grading?.length)} index={index + grading?.length} fetchGrades={fetchGrades} removeUser={removeUser} userProfile={userProfile} />
                        ))} */}
                    </>
                }
            </div>
        </div>
    )
}

const Grade = ({ item, setGrading, isSelected, onClick, index, grading, fetchGrades, removeUser, userProfile, setReload, reload }) => {
    const [loading, setLoading] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const handleInputChange = (e, field) => {
        const value = field === 'value' ? e.target.value : e.target.valueAsNumber;
        // Create a new array with the updated value
        const updatedGrading = grading.map((gradeItem, i) =>
            i === index ? { ...gradeItem, [field]: value } : gradeItem
        );

        setGrading(updatedGrading);
    };
    const handleSave = async (e, index) => {
        e.preventDefault()
        setLoading(true)
        console.log(grading)
        console.log(grading?.length, index)
        const gradeToSave = grading[index];
        console.log('Saved Grade:', gradeToSave);
        await addGrade({ minScore: parseInt(gradeToSave.minScore), maxScore: parseInt(gradeToSave.maxScore), value: gradeToSave.value }).then((res) => {
            console.log(res?.data)
            setLoading(false)
            toast.success("Grade added successfully")
            setReload(!reload)
        }).catch((err) => {
            console.log(err)
            setLoading(false)
        })
    };

    const handleUpdate = async (e, index) => {
        const gradeToSave = grading[index];
        console.log('Saved Grade:', gradeToSave);
        e.preventDefault()
        setIsUpdating(true)
        await updateGrade({
            id: gradeToSave?.id,
            minScore: gradeToSave?.minScore,
            maxScore: gradeToSave?.maxScore,
            value: gradeToSave?.value,
            isActive: true
        }).then((res) => {
            setIsUpdating(false)
            setReload(!reload)
            toast.success("Grade updated successfully")
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            setIsUpdating(false)
        })
    }

    const handleDelete = async (e, index) => {
        const gradeToDel = grading[index];
        console.log('Saved Grade:', gradeToDel);
        setIsDeleting(true)
        await deleteGrade({ value1: gradeToDel?.id, value2: userProfile?.user?.learningInstitutionId }).then(() => {
            toast.success("Grade deleted successfully")
            setGrading((prevGrading) => prevGrading.filter((_, i) => i !== index));
            setReload(!reload)
            setIsDeleting(false)
        }).catch((err) => {
            if (err?.response?.status == 401) {
                removeUser()
                window.location.href = "/login"
            }
            setIsDeleting(false)
        })
    }

    return (
        <form className='flex gap-5 items-end' onClick={onClick} onSubmit={(e) => handleSave(e, index)}>
            <div>
                <p className='text-white text-[15px] mb-2'>Min</p>
                <div className='w-[100px]'>
                    <input
                        type="number"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Min score'
                        // min={0}
                        max={100}
                        value={item?.minScore === "" ? "" : item.minScore}
                        onChange={(e) => handleInputChange(e, 'minScore')}
                        required
                    />
                </div>
            </div>
            <div>
                <p className='text-white text-[15px] mb-2'>Max</p>
                <div className='w-[100px]'>
                    <input
                        type="number"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Max score'
                        // min={0}
                        max={100}
                        value={item?.maxScore === "" ? "" : item.maxScore}
                        onChange={(e) => handleInputChange(e, 'maxScore')}
                        required
                    />
                </div>
            </div>
            <div>
                <p className='text-white text-[15px] mb-2'>Grade</p>
                <div className='w-[100px]'>
                    <input
                        type="text"
                        className='w-full p-4 rounded-md text-[14px] outline-none'
                        placeholder='Grade'
                        value={item?.value?.toUpperCase()}
                        onChange={(e) => handleInputChange(e, 'value')}
                        required
                    />
                </div>
            </div>
            {item?.id  ?
                <div className='flex gap-5'>
                    <div className='w-[100px]'>
                        <div type='button' className='p-4 rounded-md bg-white text-[#008FD2] text-[14px] w-full flex justify-center items-center cursor-pointer' disabled={index !== isSelected} onClick={(e) => handleUpdate(e, index)}>{isUpdating ? <Scale height={10} /> : "Update"}</div>
                    </div>
                    <div className='w-[100px]'>
                        <div type='button' className='p-4 rounded-md bg-white text-red-400 text-[14px] w-full flex justify-center items-center cursor-pointer' disabled={index !== isSelected} onClick={(e) => handleDelete(e, index)}>{isDeleting ? <Scale height={10} /> : "Delete"}</div>
                    </div>
                </div>
                :

                <div className='w-[100px]'>
                    <button className='p-4 rounded-md bg-white text-[#008FD2] text-[14px] w-full' disabled={index !== isSelected}>{loading ? <Scale height={10} /> : "Save"}</button>
                </div>
            }
        </form>
    )
}
