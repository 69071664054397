import React, { useState } from "react";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { FormInputFields } from "../../GetStarted/FormInputFields";
import Inputs from "../../../CustomUIElements/Inputs/Inputs";
import { LiaTimesSolid } from "react-icons/lia";
import ModalContainer from "../../../CustomUIElements/Modal/ModalContainer";
import AllIdeasTable from "./AllIdeasTable";
import useIdeaStore from "../../../store/ideaStore";
import { useMutation, useQuery } from "react-query";
import { fetchAllIdeas } from "../../../utils/fetchAllIdeas";
import useAuthStore from "../../../store/authStore";
import BorderedTextArea from "../../../CustomUIElements/Inputs/BorderTextArea";
import BorderedInputs from "../../../CustomUIElements/Inputs/BorderInputs";
import BorderedSelect from "../../../CustomUIElements/Inputs/BorderedSelect";
import { fetchCategories } from "../../../utils/fetchCategories";
import { createIdea } from "../../../utils/createIdea";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { HiOutlineSearch } from "react-icons/hi";
import { activateEntityStatus } from "../../../utils/activateEntityStatus";
import { deleteIdea } from "../../../utils/deleteIdea";
import ConfirmationModal from "../../../CustomUIElements/Modal/ConfirmationModal";

function IdeaHome() {
  const [openModal, setOpenModal] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const [selectedRows, setSelectedRows] = useState([])
  const [searchQuery, setSearchQuery] = useState("");

  const { userProfile, removeUser } = useAuthStore();
  const navigate = useNavigate()
  
  const { ideas, saveIdeas, categories, saveCategories } = useIdeaStore();
  const { isError } = useQuery("fetchCategories", fetchCategories, {
    onSuccess: ({ data }) => {
      saveCategories(data.data);
    },
    onError: ({ response }) => {
      console.log(`${response?.data?.status}`);
    },
  });

  const { isLoading: loadingIdeas, mutate: fetchIdeas, refetch } = useQuery("fetch ideas", fetchAllIdeas, {
    enabled: true,
    onSuccess: ({ data }) => {
      data?.data?.forEach(item => {
        item.ideaOwner = `${item.ideaOwner.firstName} ${item.ideaOwner.lastName}`;
      });
      console.log('data', data);
      saveIdeas(data);
    },
    onError: ({ response }) => {
      if (response?.status === 401) {
        removeUser();
      }
    },
  });
  const data = ideas?.data;

  const { mutate, isLoading: creatingIdea } = useMutation(createIdea, {
    onSuccess: ({ data }) => {
      toast.success(`${data.status}`);
      fetchIdeas()
    },
    onError: ({ response }) => {
      console.log(response)
      if (response.status === 500) {
        toast.error(`${response?.data}`);
      } else {
        toast.error(`${response?.statusText}`);
      }
      if (response.status === 401) {
        removeUser();
        navigate("/login");
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      name: formValues?.name,
      description: formValues?.description,
      categoryId: formValues?.categoryId,
      groupId: 0,
    });
  };

  const mutation = useMutation(deleteIdea);

  const handleBulkDelete = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        await mutation.mutateAsync(row?.id);
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk delete operation");
    }
  };
  const activateMutation = useMutation({
    mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
  })
  const handleBulkActivate = async () => {
    try {
      await Promise.all(selectedRows?.map(async (row) => {
        // Call the mutation function with the ID
        console.log(row?.id)
        await activateMutation.mutateAsync({ entityName: "Ideas", id: row?.id });
      }));
      toast.success("Operation completed successfully");
      refetch();
      setOpenModal({ modalName: "", showModal: false });
    } catch (error) {
      console.error("Bulk delete error:", error);
      toast.error("An error occurred during bulk activate operation");
    }
  };

  return (
    <div className="min-h-[85vh]">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-4">
          <h1 className="text-[20px] font-semibold">Manage Ideas</h1>
          <div className="relative">
            <input
              className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <span className="absolute top-[14px] left-[15px] text-[20px]">
              <HiOutlineSearch />
            </span>
          </div>
        </div>
      </div>
      {selectedRows?.length > 0 &&
        <div className="flex gap-2 items-center">
          <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
          <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
        </div>
      }
      <div className="mt-4 mb-8">
        <AllIdeasTable Idea={data} loading={loadingIdeas} selectedRows={selectedRows}
          setSelectedRows={setSelectedRows} refetch={refetch} searchQuery={searchQuery} />
      </div>
      {openModal && (
        <ModalContainer height="auto">
          <div className="p-8">
            <div className="flex items-center justify-between text-[1.8rem] mb-4">
              <h3 className="">Add New Idea</h3>
              <LiaTimesSolid
                onClick={() => setOpenModal(false)}
                className="cursor-pointer"
              />
            </div>
            <form className="space-y-[2rem]" onSubmit={handleSubmit}>
              <BorderedInputs
                label="Name of Idea"
                isRequired
                value={formValues?.name}
                handleChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
              />
              <BorderedTextArea
                label="Write a short description"
                value={formValues?.description}
                handleChange={(e) => {
                  setFormValues({ ...formValues, description: e.target.value });
                  setWordCount(e.target.value?.length);
                }}
                maxWordCount
              />
              <p className="!ml-auto !mt-[-.2rem] text-right text-[#103240B2] font-medium">
                {wordCount}/150
              </p>
              <select className="w-full bg-transparent border-b border-0 outline-0 rounded outline-none border-[#F7F7F7] py-[.5rem] text-[16px] leading-[2.4rem]" value={formValues?.categoryId} onChange={(e) => setFormValues({ ...formValues, categoryId: e.target.value })} >
                <option value="" className="">Select a category</option>
                {categories?.map((c) => (
                  <option key={c.id} value={c.id}>{c.name}</option>
                ))}
              </select>
              <div className="!mt-[3rem]">
                <Buttons
                  variant={
                    formValues?.name &&
                      formValues?.description &&
                      formValues?.categoryId &&
                      !creatingIdea
                      ? "primary"
                      : "disabled"
                  }
                  type="submit"
                  text={creatingIdea ? "Submitting..." : "Submit"}
                />
              </div>
            </form>
          </div>
        </ModalContainer>
      )}
      <ConfirmationModal
        showModal={openModal.modalName === "delete" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to delete these ideas?"
        onConfirm={handleBulkDelete}
      // loading={deletingUser}
      />
      <ConfirmationModal
        showModal={openModal.modalName === "activate" && openModal.showModal}
        onCancel={() => setOpenModal({ modalName: "", showModal: false })}
        message="Are you sure you want to activate these ideas?"
        onConfirm={handleBulkActivate}
      // loading={deletingUser}
      />
    </div>
  );
}

export default IdeaHome;

export const InputFields = [
  {
    label: "Idea Name",
    isRequired: true,
    type: "text",
    name: "firstName",
  },
];
