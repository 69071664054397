import React, { useState } from "react";
import CreateIdeaContent from "./CreateIdeaContent";
import BorderedInputs from "../../../CustomUIElements/Inputs/BorderInputs";
import BorderedTextArea from "../../../CustomUIElements/Inputs/BorderTextArea";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import { useMutation, useQuery } from "react-query";
import { fetchCategories } from "../../../utils/fetchCategories";
import useIdeaStore from "../../../store/ideaStore";
import useAuthStore from "../../../store/authStore";
import BorderedSelect from "../../../CustomUIElements/Inputs/BorderedSelect";
import { createIdea } from "../../../utils/createIdea";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";

function CreateIdeaBody({ setProgressBar, setOpenCreateModal, setShowBanner, refetch }) {
  const [createStep, setCreateStep] = useState("create-idea");
  const [addIdea, setAddIdea] = useState({});
  const [wordCount, setWordCount] = useState(0);
  const { userProfile, removeUser } = useAuthStore()
  const { categories, saveCategories } = useIdeaStore();
  const { groupId } = useParams()

  const navigate = useNavigate();


  console.log(userProfile?.user)
  const { isError } = useQuery("fetchCategories", fetchCategories, {
    onSuccess: ({ data }) => {
      saveCategories(data.data);
    },
    onError: ({ response }) => {
      console.log(`${response?.data?.status}`);
    },
  });



  const { mutate, isLoading } = useMutation(createIdea, {
    onSuccess: ({ data }) => {
      toast.success(`${data.status}`);
      // if (groupId) {
      //   navigate("/student/dashboard/group-ideas");
      // }
      setOpenCreateModal(false)
      refetch()
    },
    onError: ({ response }) => {
      console.log(response)
      if (response.status === 500) {
        if (response?.statusText === "An idea with name First Idea already exists.") {
          toast.error(`${response?.statusText}`);
        } else {
          setShowBanner(true)
        }
        // toast.error(`${response?.data}`)
      } else {
        toast.error(`${response?.statusText}`);
      }
      if (response.status === 401) {
        removeUser();
        navigate("/login");
      }
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    mutate({
      name: addIdea?.name,
      description: addIdea?.description,
      categoryId: addIdea?.categoryId,
      groupId: groupId,
    });
  };
  return (
    <>
      <div className="w-[65rem] mx-auto">
        {createStep === "create-idea" && (
          <CreateIdeaContent
            stepTitle="Create Group Idea"
            stepImage={<img src="/assets/idea1.svg" alt="" />}
            stepDesc="Give us a brief introduction/summary of your idea"
            addIdea={addIdea}
            setAddIdea={setAddIdea}
          >
            <div className="space-y-[2rem]">
              <BorderedInputs
                label="Name of Idea"
                isRequired
                value={addIdea?.name}
                handleChange={(e) =>
                  setAddIdea({ ...addIdea, name: e.target.value })
                }
              />
              <BorderedTextArea
                label="Write a short description"
                value={addIdea?.description}
                handleChange={(e) => {
                  setAddIdea({ ...addIdea, description: e.target.value });
                  setWordCount(e.target.value?.length);
                }}
                maxWordCount={150}
              />
              <p className="!ml-auto !mt-[-.2rem] text-right text-[#103240B2] font-medium">
                {wordCount}/150
              </p>
              <div
                className="block w-[13.8rem] ml-auto"
                onClick={() => {
                  setCreateStep("idea-category");
                  setProgressBar((prev) => prev + 33);
                }}
              >
                <Buttons
                  variant={
                    addIdea?.name && addIdea?.description ? "primary" : "disabled"
                  }
                  text="Next"
                />
              </div>
            </div>
          </CreateIdeaContent>
        )}
        {createStep === "idea-category" && (
          <CreateIdeaContent
            stepTitle="Idea Category"
            stepImage={<img src="/assets/idea2.svg" alt="" className="m-0" />}
            stepDesc="Select the category your idea falls into"
            addIdea={addIdea}
            setAddIdea={setAddIdea}
          >
            <div className="w-[65rem] mx-auto">
              <BorderedSelect
                label="Choose Category"
                options={categories}
                addIdea={addIdea}
                setAddIdea={setAddIdea}
              />
              <div className="flex justify-end ml-auto mt-4 ">
                <div className="flex gap-[1.6rem]">
                  <Buttons
                    text="Back"
                    variant="cancel"
                    handleClick={() => {
                      setCreateStep("create-idea");
                      setProgressBar((prev) => prev - 33);
                    }}
                  />
                  <Buttons
                    text="Proceed"
                    variant={isLoading || !addIdea?.categoryId ? "disabled" : "primary"}
                    handleClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </CreateIdeaContent>
        )}
      </div>
    </>
  );
}

export default CreateIdeaBody;
