import React from 'react'
import Dashboard from '../../Components/LiDashboard/Dashboard'

function Home() {
    return (
        <div className="relative top-[8%] px-16 py-8 bg-[#f1f1f1] min-h-[92vh]">
            < Dashboard />
        </div>
    )
}

export default Home