import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { fetchSingleIdea } from "../../../utils/fetchSingleIdea";
import { useNavigate, useParams } from "react-router-dom";
import Header from "./Header";
import useIdeaStore from "../../../store/ideaStore";
import NotesSection from "./Notes/NotesSection";
import useAuthStore from "../../../store/authStore";
import ShareSection from "./Share/ShareSection";
import QuestionSection from "./Questions/QuestionSection";
import { fetchMyNotes } from "../../../utils/fetchMyNotes";
import { fetchQuestions } from "../../../utils/fetchQuestions";
import Scale from "../../../CustomUIElements/Loaders/ScaleLoader";
import ChatPopup from "../../ChatBox/ChatPopup";
import { MdClose, MdOutlineMessage } from "react-icons/md";
import { Buttons } from "../../../CustomUIElements/Buttons/Buttons";
import axios from "axios";
import { updateComment, updateScore } from "../../../utils/updateIdea";
import { toast } from "react-toastify";
import { getLIGrades } from "../../../utils/addSetting";
import { useTheme } from "../../../context/ThemeContext";

function SingleIdea({ src }) {
  const { id } = useParams();
  const { removeUser, userProfile } = useAuthStore();
  const [singleIdea, setSingleIdea] = useState(null);
  const [notes, setNotes] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("")
  const [score, setScore] = useState(0)
  const [grading, setGrading] = useState([
    {
      minScore: 0,
      maxScore: 0,
      value: ""
    }
  ])
  const navigate = useNavigate();
  const { theme, themeStyles } = useTheme()
  const { isError, isLoading, isFetching, refetch } = useQuery(
    "fetchSingleIdea",
    () => fetchSingleIdea({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setSingleIdea(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
      refetchInterval: false,
      retry: false
    }
  );
  const { refetch: fetchNotes } = useQuery(
    "fetchMyNotes",
    () => fetchMyNotes({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setNotes(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );
  const { refetch: fetchIdeaQuestions } = useQuery(
    "fetchQuestions",
    () => fetchQuestions({ id: parseInt(id) }),
    {
      onSuccess: ({ data }) => {
        setQuestions(data.data);
      },
      onError: ({ response }) => {
        if (response.data.status === 401) {
          removeUser();
        }
      },
      enabled: false,
    }
  );

  const fetchGrades = async () => {
    setLoading(true)
    await getLIGrades({ id: userProfile?.user?.learningInstitutionId }).then((res) => {
      console.log(res?.data?.data)
      if (res?.data?.data?.length < 1) {
        setGrading([
          {
            minScore: "",
            maxScore: "",
            value: ""
          }
        ])
      } else {
        setGrading(res?.data?.data)
      }
      setLoading(false)
    }).catch((err) => {
      if (err?.response?.status == 401) {
        removeUser()
        window.location.href = "/login"
      }
      console.log(err)
      setLoading(false)
    })
  }
  useEffect(() => {
    fetchGrades()
  }, [])

  const handleAddScoreOrComment = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const idParsed = parseInt(id);
      const requests = [];

      if (comment) {
        requests.push(updateComment({ value1: idParsed, value2: comment }));
      }

      if (score > 0) {
        requests.push(updateScore({ value1: idParsed, value2: score }));
      }

      const responses = await Promise.all(requests);

      const allSuccessful = responses.every(res => res.status === 200);

      if (allSuccessful) {
        // Handle success logic here
        toast.success("Score has been added successfully")
      }

    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (isError) navigate("/login");
  });
  useEffect(() => {
    refetch();
    fetchNotes();
    fetchIdeaQuestions();
  }, [id]);

  useEffect(() => {
    if (singleIdea) {
      setScore(singleIdea?.score)
      setComment(singleIdea?.comment)
    }
  }, [singleIdea])

  return (
    <div className="!my-[4.4rem] app-container">
      {isLoading || isFetching ? (
        <div className="flex justify-center items-center h-[60dvh]">
          <Scale />
        </div>
      ) : (
        <div className={`${src ? "w-[90%]" : "w-[82rem]"} mx-auto`}>
          <Header categoryId={singleIdea?.categoryId} singleIdea={singleIdea} src={src} />
          <div className="flex mt-[2.4rem] pb-[2.4rem] border-b items-start justify-between">
            <NotesSection notes={notes} setNotes={setNotes} src={src} />
            <ShareSection src={src} />
          </div>
          <div className="mt-[2.4rem]">
            <QuestionSection
              questions={questions}
              setQuestions={setQuestions}
              singleIdea={singleIdea}
              setSingleIdea={setSingleIdea}
              src={src}
            />
          </div>
          {userProfile.user?.userRole === "Lecturer" &&
            <form className="mt-[2.4rem] flex gap-[20px]" onSubmit={handleAddScoreOrComment}>
              <div className="w-[70%]">
                <p className="text-[16px]">Lecturer's Comment</p>
                <textarea className="w-full p-4 rounded-md border outline-none resize-none text-[15px]" cols={15} value={comment} onChange={(e) => setComment(e.target.value)} required />
              </div>
              <div className="w-[20%]">
                <p className="text-[16px]">Score (number)</p>
                <input type="number" className="w-full p-4 rounded-md border outline-none text-[15px] h-[66px]" value={score} onChange={(e) => setScore(e.target.valueAsNumber)} min={0} max={100} required />
                <div className="w-full border p-4 mt-2 space-y-2 text-[15px]">
                  {grading?.map((grade) => (
                    <div className="flex items-center justify-between">
                      <p>{grade?.minScore} - {grade?.maxScore}</p>
                      <p className="uppercase">{grade?.value}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[10%] mt-[24px]">
                <Buttons text={loading ? "Loading" : "Save"} variant={loading ? "disabled" : "primary"} type="submit" />
              </div>
            </form>
          }
          {userProfile?.user?.userRole === "Student" &&
            <div className="mt-[2.4rem] flex gap-[20px]">
              <div className="w-[70%]">
                <p className="text-[16px]">Lecturer's Comment</p>
                <div className="w-full p-4 rounded-md border outline-none resize-none text-[15px] min-h-[50px]">
                  {singleIdea?.comment}
                </div>
              </div>
              <div className="w-[10%]">
                <p className="text-[16px]">Score</p>
                <div className="w-full p-4 rounded-md border outline-none resize-none text-[15px] min-h-[50px]">
                  {singleIdea?.score}
                </div>
              </div>
              <div className="w-[10%]">
                <p className="text-[16px]">Grade</p>
                <div className="w-full p-4 rounded-md border outline-none resize-none text-[15px] min-h-[50px]">
                  {singleIdea?.ideaGrade?.grade?.value}
                </div>
              </div>
            </div>
          }
        </div>
      )}
      {(userProfile?.user?.userRole === "Lecturer" || userProfile?.user?.userRole === "Student") && openChat && <div className='fixed bottom-[90px] right-[30px]'>
        <ChatPopup />
      </div>}
      <div className='fixed bottom-[30px] right-[30px]'>
        <div className='bg-[#008FD2] w-[50px] h-[50px] rounded-full flex justify-center items-center cursor-pointer' style={{ backgroundColor: theme.backgroundColor }} onClick={() => setOpenChat((prev) => !prev)}>
          {(userProfile?.user?.userRole === "Lecturer" || userProfile?.user?.userRole === "Student") && openChat ? <MdClose color='white' size={20} /> : <MdOutlineMessage color='white' size={20} />}
        </div>
      </div>
    </div>
  );
}

export default SingleIdea;
