import React, { useState } from 'react'
import { Buttons } from '../../../CustomUIElements/Buttons/Buttons'
import useAuthStore from '../../../store/authStore';
import { useMutation, useQuery } from 'react-query';
import ModalContainer from '../../../CustomUIElements/Modal/ModalContainer';
import { LiaTimesSolid } from 'react-icons/lia';
import Inputs from '../../../CustomUIElements/Inputs/Inputs';
import { toast } from 'react-toastify';
import { fetchSubscriptions } from '../../../utils/fetchSubscriptions';
import { addSubscription } from '../../../utils/addSubscription';
import SubscriptionTable from './SubscriptionTable';
import ReactQuill from "react-quill";
import parse from "html-react-parser"
import { HiOutlineSearch } from 'react-icons/hi';
import { activateEntityStatus } from '../../../utils/activateEntityStatus';
import { deleteSubscription } from '../../../utils/deleteSubscription';
import ConfirmationModal from '../../../CustomUIElements/Modal/ConfirmationModal';
import { ChromePicker } from 'react-color';

function SubscriptionHome() {
    const [openModal, setOpenModal] = useState(false);
    const [formValues, setFormValues] = useState({});
    const { removeUser } = useAuthStore()
    // const [isLoading, setIsLoading] = useState(false);
    const [funnels, setFunnels] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [desc, setDesc] = useState("")
    const [searchQuery, setSearchQuery] = useState("");

    const { isLoading: loadingFunnels, isRefetching, refetch } = useQuery("fetchSubscriptions", fetchSubscriptions, {
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: ({ data }) => {
            setFunnels(data?.data)
        },
        onError: ({ response }) => {
            if (response?.status === 401) {
                removeUser()
                window.location.href = "/login"
            }
        }
    })

    const { mutate, isLoading } = useMutation(addSubscription, {
        onSuccess: ({ data }) => {
            setOpenModal(false)
            toast.success(`${data.status}`)
            setFormValues({})
            refetch()
        },
        onError: ({ response }) => {
            toast.error(`${response?.data?.status}`);
        },
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        mutate({
            topic: formValues?.topic,
            description: desc,
            colourCode: formValues?.colourCode,
            price: parseInt(formValues?.price),
            expiryInMonths: formValues?.expiryInMonths,
            noOfIdeas: formValues?.noOfIdeas
        });
    };

    const mutation = useMutation(deleteSubscription);

    const handleBulkDelete = async () => {
        try {
            await Promise.all(selectedRows?.map(async (row) => {
                // Call the mutation function with the ID
                await mutation.mutateAsync(row?.id);
            }));
            toast.success("Operation completed successfully");
            refetch();
            setOpenModal({ modalName: "", showModal: false });
        } catch (error) {
            console.error("Bulk delete error:", error);
            toast.error("An error occurred during bulk delete operation");
        }
    };
    const activateMutation = useMutation({
        mutationFn: ({ entityName, id }) => activateEntityStatus(entityName, id)
    })
    const handleBulkActivate = async () => {
        try {
            await Promise.all(selectedRows?.map(async (row) => {
                // Call the mutation function with the ID
                console.log(row?.id)
                await activateMutation.mutateAsync({ entityName: "Subscriptions", id: row?.id });
            }));
            toast.success("Operation completed successfully");
            refetch();
            setOpenModal({ modalName: "", showModal: false });
        } catch (error) {
            console.error("Bulk delete error:", error);
            toast.error("An error occurred during bulk activate operation");
        }
    };

    const handleColorChange = (color) => {
        setFormValues({ ...formValues, colourCode: color.hex })
    }

    return (
        <div className='min-h-[85vh]'>
            <div className="flex justify-between items-center  mb-[2rem]">
                <div className="flex items-center gap-4">
                    <h1 className="text-[20px] font-semibold">Manage Subscription</h1>
                    <div className="relative">
                        <input
                            className="w-[343px] h-[45px] rounded-[8px]  pl-[36px] pr-4 text-[16px] relative outline-none"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        <span className="absolute top-[14px] left-[15px] text-[20px]">
                            <HiOutlineSearch />
                        </span>
                    </div>
                </div>
                <div className="w-fit">
                    <Buttons
                        type="button"
                        text="Add New Subscription"
                        variant="primary"
                        handleClick={() => setOpenModal({ modalName: "add", showModal: true })}
                    />
                </div>
            </div>
            {selectedRows?.length > 0 &&
                <div className="flex gap-2 items-center">
                    <button className="px-4 py-1 rounded-md bg-red-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "delete", showModal: true })}>Disable</button>
                    <button className="px-4 py-1 rounded-md bg-green-500 text-white text-[15px]" onClick={() => setOpenModal({ modalName: "activate", showModal: true })}>Enable</button>
                </div>
            }
            <div className="mt-4 mb-8">
                <SubscriptionTable data={funnels} loading={loadingFunnels || isRefetching} refetch={refetch} selectedRows={selectedRows} setSelectedRows={setSelectedRows} searchQuery={searchQuery} />
            </div>
            {openModal?.modalName == "add" && openModal?.showModal && (
                <ModalContainer height="500px">
                    <div className="p-8">
                        <div className="flex items-center justify-between text-[1.8rem]  mb-4">
                            <h3 className="">Add New Subscription</h3>
                            <LiaTimesSolid
                                onClick={() => { setOpenModal(false); setFormValues({}) }}
                                className="cursor-pointer"
                            />
                        </div>
                        <form className="space-y-[1.6rem]"
                            onSubmit={handleSubmit}
                        >
                            <Inputs
                                key={"topic"}
                                type={"text"}
                                label={"Topic"}
                                name={"topic"}
                                isRequired={true}
                                value={formValues["topic"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"colourCode"}
                                type={"text"}
                                label={"Colour Code"}
                                name={"colourCode"}
                                isRequired={true}
                                value={formValues["colourCode"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                                readOnly={true}
                            />
                            <center>
                                <ChromePicker color={formValues?.colourCode} onChange={(color) => handleColorChange(color)} disableAlpha={true} />
                            </center>
                            <Inputs
                                key={"noOfIdeas"}
                                type={"text"}
                                label={"Number of Ideas"}
                                name={"noOfIdeas"}
                                isRequired={true}
                                value={formValues["noOfIdeas"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <ReactQuill
                                theme="snow"
                                value={desc}
                                onChange={setDesc}
                                className="peer !text-[1.6rem] h-[22rem] !relative !mb-[1.6rem]"
                                placeholder="Description"
                            />
                            <Inputs
                                key={"price"}
                                type={"text"}
                                label={"Price"}
                                name={"price"}
                                isRequired={true}
                                value={formValues["price"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <Inputs
                                key={"expiryInMonths"}
                                type={"text"}
                                label={"Expiriy In Months"}
                                name={"expiryInMonths"}
                                isRequired={true}
                                value={formValues["expiryInMonths"]}
                                handleChange={(e) =>
                                    setFormValues({
                                        ...formValues,
                                        [e.target.name]: e.target.value,
                                    })
                                }
                            />
                            <div className="!mt-[3rem]">
                                <Buttons
                                    variant={
                                        formValues?.topic &&
                                            formValues?.colourCode &&
                                            formValues.price &&
                                            formValues?.expiryInMonths &&
                                            !isLoading
                                            ? "primary"
                                            : "disabled"
                                    }
                                    type="submit"
                                    text={isLoading ? "Submitting..." : "Submit"}
                                />
                            </div>
                        </form>
                    </div>
                </ModalContainer>
            )}
            <ConfirmationModal
                showModal={openModal.modalName === "delete" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to delete these subscriptions?"
                onConfirm={handleBulkDelete}
            // loading={deletingUser}
            />
            <ConfirmationModal
                showModal={openModal.modalName === "activate" && openModal.showModal}
                onCancel={() => setOpenModal({ modalName: "", showModal: false })}
                message="Are you sure you want to activate these subscriptions?"
                onConfirm={handleBulkActivate}
            // loading={deletingUser}
            />
        </div>

    )
}

export default SubscriptionHome